<template>
  <div :class="classes">
    <a
      :href="getContentDetailPath(content.id)"
      v-ripple="isPublishable ? $utilities.getRippleOptions('light') : false">
      <div class="dp-top-favorite-recipe-card__thumbnail">
        <img :src="content.image_url" :alt="content.name" />
      </div>

      <div ref="cardContent" class="dp-top-favorite-recipe-card__content">
        <h2 class="dp-top-favorite-recipe-card__title">
          {{ content.name }}
        </h2>

        <div
          v-if="content.capsule && isPublishPeriod(content.capsule)"
          class="dp-top-favorite-recipe-card__capsule">
          <Icon name="capsuleB" color="dpGrey66" :width="11" :height="11" />
          <span class="dp-top-favorite-recipe-card__capsule-name">{{
            content.capsule.name
          }}</span>
        </div>

        <p v-if="!isPublishable" class="dp-top-favorite-recipe-card__closed">
          {{ WORDS.DRIP_POD_TOP.CLOSED }}
        </p>
      </div>
    </a>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { defineComponent, ref, computed } from '@vue/composition-api';
import { usePublishable } from '@/composables/usePublishable';

export default defineComponent({
  props: {
    content: {
      type: Object,
      default: undefined
    }
  },

  setup: (props, context) => {
    const { isPublishPeriod } = usePublishable();
    const cardContent = ref(undefined);
    const isPublishable = computed(() => isPublishPeriod(props.content));
    const getContentDetailPath = (id) =>
      isPublishable.value && id
        ? context.root.$customUrlScheme.dp(`/pro-recipe/detail/${id}`)
        : false;
    const classes = computed(() => [
      'dp-top-favorite-recipe-card',
      !isPublishable.value ? 'dp-top-favorite-recipe-card--disabled' : false
    ]);

    return {
      WORDS,
      isPublishable,
      getContentDetailPath,
      classes,
      cardContent,
      isPublishPeriod
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-top-favorite-recipe-card {
  &__closed {
    margin: 0 0 -8px !important;
    font-size: 10px;
  }

  &--disabled {
    a {
      background-color: variables.$dpGreyF1 !important;
      border: rgba($color: variables.$dpGreyAa, $alpha: 15%) !important;

      > * {
        opacity: 0.7;
      }
    }
  }

  a {
    display: grid;
    grid-template-columns: 1fr 2.6fr;
    margin: 0;
    padding: 16px;
    background-color: variables.$dpWhite01;
    width: 100%;
    box-shadow: 0 4px 16px rgba(#000, 0.16);
    border-radius: 4px;
    overflow: hidden;
    text-decoration: none;
    color: variables.$dpBlack01;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &__capsule {
    display: grid;
    place-items: center start;
    grid-template-columns: 12px 1fr;
    gap: 2px;
    color: variables.$dpGrey66;
    font-size: 10px;

    &-name {
      @include mixin.clamp(1);
    }
  }

  &__title {
    @include mixin.clamp(2);

    margin-bottom: 8px;
    font-size: 16px;
    line-height: 1.3;
    font-weight: bold;
  }

  &__thumbnail {
    @include mixin.imageAspectRatio(343, 343);

    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    display: grid;
    grid-template-rows: auto 1fr;
    place-items: start;
    padding-left: 12px;
  }
}
</style>
